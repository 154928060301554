
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  apiGameBlackIpDetail,
  apiGameBlackIpEdit,
  apiGameBlackIpAdd, apiGameBlackIpLists
} from '@/api/setting'
@Component({
    components: {}
})
export default class GameIPEdit extends Vue {
    @Prop() aid?: any
    @Prop() cid?: number
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false

    $refs!: {
        formRef: any
    }
    form = {
        ip: '',
        ip_location: '',


    }

    // 表单验证
    formRules = {
      ip: [
            {
                required: true,
                message: '请输入ip',
                trigger: 'blur'
            }
        ],
    }
    /** E Data **/

    // 分类详情
    getDetail() {
        apiGameBlackIpDetail({
            aid: this.aid,
            id: this.cid
        })
            .then((res: any) => {
                this.form = res
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }


    // 新增分类
    onAdd() {
        apiGameBlackIpAdd({
            ...this.form,
            //aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 编辑分类
    onEdit() {
        apiGameBlackIpEdit({
            ...this.form,
            id: this.cid,
            //aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 提交
    onSubmit() {
        this.$refs.formRef.validate((valid: any) => {
            if (!valid) {
                return
            }
            if (this.cid) {
                this.onEdit()
            } else {
                this.onAdd()
            }
        })
    }

    // 弹窗打开触发
    onTrigger() {
        this.visible = true


        if (this.cid) {
            this.getDetail()
        }
    }

    // 关闭弹窗
    close() {
        this.visible = false

        this.form = {
            ip: '',
            ip_location: '',

        }
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
